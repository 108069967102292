import PropTypes from 'prop-types';

import { contentfulImageType } from 'types/common/contentfulImage.types';

export const imageTextBlockProps = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: contentfulImageType.isRequired,
};

export const imageTextBlockType = PropTypes.shape({ ...imageTextBlockProps });
