import { css } from '@styled-system/css';
import styled from 'styled-components';

import { ProfileCard } from 'humanity/components/profileCard';
import SimpleRichText from 'humanity/components/simpleRichText';
import Container from 'humanity/primitives/container';
import Flex from 'humanity/primitives/flex';
import Heading from 'humanity/primitives/heading';
import Section from 'humanity/primitives/section';
import { profileCardsSectionProps } from 'humanity/sections/profileCardsSection/profileCardsSection.types.js';
import { getPaddingValues } from 'utils/getPaddingValues';

const StyledFlex = styled(Flex)(
  css({
    flexDirection: ['column', null, null, 'row'],
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    my: 4,
  })
);
const ProfileCardsSection = ({
  heading,
  body,
  authorsCollection,
  topPadding,
  bottomPadding,
  backgroundColor,
}) => (
  <Section
    pt={getPaddingValues(topPadding || 128)}
    pb={getPaddingValues(bottomPadding || 128)}
    bg={backgroundColor || 'white'}
  >
    <Container>
      <Heading textAlign="center" variant="h3" mb="3">
        {heading}
      </Heading>
      <SimpleRichText content={body.json} mb="7" />
      <StyledFlex gap={5} rowGap={5}>
        {authorsCollection.items.map((author) => (
          <ProfileCard key={author.name} {...author} />
        ))}
      </StyledFlex>
    </Container>
  </Section>
);

ProfileCardsSection.propTypes = {
  ...profileCardsSectionProps,
};

export default ProfileCardsSection;
