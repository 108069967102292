import useSWR from 'swr';
import styled, { ThemeProvider } from 'styled-components';
import css from '@styled-system/css';

import { authorTooltipBySlug } from 'humanity/components/authorTooltip/authorTooltip.query';
import Tooltip from 'humanity/components/tooltip';
import AuthorCard from 'humanity/components/authorCard';
import { theme } from 'utils/theme.v2';
import Link from 'humanity/primitives/link';
import { authorTooltipProps } from 'humanity/components/authorTooltip/authorTooltip.types';
import { fetchViaApi } from 'lib/contentful';

const StyledLink = styled(Link)(
  css({
    fontWeight: 400,
    color: 'inherit',
    textDecoration: 'underline',
  })
);

const fetcher = (query) =>
  fetchViaApi(query).then((entry) => entry?.data?.authorCollection?.items?.[0]);

const AuthorTooltip = ({ name, slug }) => {
  const query = authorTooltipBySlug(slug);
  const { data: author, error } = useSWR(query, fetcher);

  const TooltipCard = (
    <AuthorCard
      as="section"
      name={author?.name}
      authorRole={author?.role}
      image={author?.image}
      description={author?.description}
      variant="tooltip"
      textAlign="left"
    />
  );

  return (
    <ThemeProvider theme={theme}>
      {author && !error && (
        <Tooltip
          title={TooltipCard}
          name={`AuthorTooltip_${slug}`}
          cardProps={{ bg: 'white', p: 5, maxWidth: 482 }}
          placement="bottom"
        >
          <StyledLink href={`/learn/contributors/${slug}/`}>{name}</StyledLink>
        </Tooltip>
      )}
      {(error || !author) && (
        <StyledLink href={`/learn/contributors/${slug}/`}>{name}</StyledLink>
      )}
    </ThemeProvider>
  );
};

AuthorTooltip.propTypes = {
  ...authorTooltipProps,
};

export default AuthorTooltip;
