/* eslint-disable react/no-danger */
import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, useTheme } from 'styled-components';
import css from '@styled-system/css';
import { format } from 'date-fns/format';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Circle } from 'react-feather';
import useSWR from 'swr';

import { contentfulLearningCenterArticleType } from 'types';
import getContentfulImgixUrl from 'utils/getContentfulImgixUrl';
import { theme as themeV2 } from 'utils/theme.v2';
import useProgressScrollBar from 'hooks/useProgressScrollBar';
import Heading from 'legacy/components/heading';
import Text from 'legacy/components/text';
import ContentfulRichText from 'legacy/components/contentfulRichText';
import Flex from 'legacy/components/flex';
import Box from 'legacy/components/box';
import Divider from 'legacy/components/divider';
import SocialSharingButtons from 'legacy/components/socialSharingButtons';
import ArticleAuthorCard from 'legacy/components/articleAuthorCard';
import ProgressBar from 'legacy/components/progressBar';
import Image from 'legacy/components/image';
import KeyTakeaways from 'legacy/components/keyTakeaways';
import generateHtmlIdFromContent from 'utils/generateHtmlIdFromContent';
import AuthorTooltip from 'humanity/components/authorTooltip';
import Link from 'legacy/components/link';
import TableOfContents from 'humanity/components/tableOfContents';
import CtaBlock from 'humanity/components/ctaBlock';
import { ctaBlockById } from 'humanity/components/ctaBlock/ctaBlock.query';
import { fetchViaApi } from 'lib/contentful';

const DesktopTocWrapper = styled(Box)(
  css({
    display: ['none', null, null, null, 'block'],
    width: '27.5%',
    minWidth: '276px',
  })
);

const DesktopToc = styled(Box)(({ type }) =>
  css({
    position: 'sticky',
    top: type === 'learningCenter' ? 124 : 172,
    left: 'calc(27.5% - 276px)',
    bottom: 64,
    mt: '112px',
    width: 276,
  })
);

const MobileToc = styled(Box)(
  css({
    display: ['block', null, null, null, 'none'],
    mt: 5,
  })
);

const Anchor = styled('span')(
  css({
    display: 'block',
    position: 'relative',
    top: [-64, null, null, -80],
    visibility: 'hidden',
  })
);

const ArticleContainer = styled(Box)(({ showTableOfContents }) =>
  css({
    width: showTableOfContents ? ['100%', null, null, null, '72.5%'] : '100%',
  })
);

const ArticleWrapper = styled(Box)(({ showTableOfContents }) =>
  css({
    maxWidth: '50rem',
    ml: showTableOfContents ? ['auto', null, null, null, 4] : 'auto',
    mr: 'auto',
    pt: [5, null, 6, 7],
    px: ['1.5rem', null, 0],
  })
);

const StyledHeading = styled(Box)(
  css({
    px: ['1.5rem', null, 4, null, 0],
    borderRadius: 'large',
    bg: 'white',
  })
);

const StyledArticle = styled.article(
  css({
    pt: 4,
    mx: 'auto',
    maxWidth: '37rem',
    borderRadius: 'large',
    bg: 'white',
    width: '100%',
  })
);

const StyledCircleOne = styled(Circle)(
  css({
    m: '0 10px',
    stroke: 'none',
    display: ['none', 'flex'],
  })
);

const StyledCircleTwo = styled(Circle)(
  css({
    m: '0 10px',
    stroke: 'none',
  })
);

const StyledFlex = styled(Flex)(
  css({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

const EyebrowText = styled(Box)(
  css({
    display: 'flex',
    flexDirection: ['column', 'row'],
    justifyContent: 'center',
    alignItems: 'center',
    mt: 0,
    color: 'blue',
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
  })
);

const StyledText = styled(Text)(
  css({
    lineHeight: 2,
    '& > b': {
      fontWeight: 600,
    },
  })
);

const LearningCenterProgressBar = styled(ProgressBar)(
  css({
    position: 'fixed',
    zIndex: 1,
  })
);

const FeaturedImage = styled(Image)(
  css({
    mt: 4,
    '& img': {
      borderRadius: 'large',
    },
  })
);

const ArticleBody = styled(Box)(
  css({
    my: [5, null, 6, 7],
    '& table': {
      display: 'block',
      maxWidth: 'fit-content',
      overflowX: 'auto',
      mx: [0, null, null, null, 'auto'],
      width: ['100%', null, null, null, '140%'],
      borderRadius: 'default',
      borderSpacing: 0,
      px: 2,
      pb: 2,
      bg: 'blue100',
      fontFamily: 'body',
      color: 'text',
      '& b, & strong': {
        fontWeight: 700,
        color: 'blue',
      },
      '& highlight': {
        fontWeight: 700,
        color: 'teal40',
      },
      '& th': {
        p: 3,
        fontWeight: 400,
        textAlign: 'left',
      },
      '& td': {
        py: 2,
        px: 3,
      },
      '& thead': {
        '& tr': {
          bg: 'blue100',
        },
      },
      '& tbody': {
        '& > tr': {
          '&:nth-of-type(odd)': {
            bg: 'white',
            '[rowspan]:not([rowspan=""])': {
              bg: 'blue100',
            },
          },
          '&:nth-of-type(even)': {
            bg: 'blue100',
          },
        },
      },
    },
  })
);

const BodyHeading = styled(Heading)(
  css({
    mt: 7,
    fontSize: [3, null, 4, null, 5],
    '& + p, & + ul': {
      mt: 3,
    },
    '& > a': {
      color: 'blue40',
      textDecoration: 'underline',
    },
  })
);

const ArticleHeading = styled(Heading)(
  css({
    '& > a': {
      color: 'blue40',
      textDecoration: 'underline',
    },
    'p + &, ul + &, ol + &': {
      mt: 4,
    },
    'h1 + &, h2 + &, h3 + &, h4 + &, h5 + &': {
      mt: 4,
    },
  })
);

const TableWrapper = styled(Box)(
  css({
    ml: [0, null, null, null, '-20%'],
    width: ['100%', null, null, null, '140%'],
  })
);

const EditorialLink = styled(Link)(
  css({
    fontWeight: 'inherit',
    color: 'inherit',
    textDecoration: 'underline',
  })
);

const ByLineText = styled(Box)(
  css({
    mt: 2,
    color: 'blue40',
    textAlign: 'center',
  })
);

const renderOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <ArticleHeading as="h1" fontSize={[4, null, 5, null, 6]}>
        {children}
      </ArticleHeading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <>
        <Anchor id={generateHtmlIdFromContent(node.content[0]?.value)} />
        <BodyHeading as="h2">{children}</BodyHeading>
      </>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <ArticleHeading as="h3" fontSize={[2, null, 3, null, 4]}>
        {children}
      </ArticleHeading>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <ArticleHeading as="h4" fontSize={[2, null, null, null, 3]}>
        {children}
      </ArticleHeading>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <ArticleHeading as="h5" fontSize={2}>
        {children}
      </ArticleHeading>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <StyledText mt={3}>{children}</StyledText>,
  },
  renderMark: {
    [MARKS.CODE]: (text) =>
      text.includes('table') ? (
        <TableWrapper dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <span dangerouslySetInnerHTML={{ __html: text }} />
      ),
  },
};

// Broke this out since the hook was causing the entire article to rerender every state update.
const ProgressBarContainer = ({ articleRef }) => {
  const scrollPosition = useProgressScrollBar(articleRef);

  return <LearningCenterProgressBar progress={scrollPosition} barColor="teal" />;
};

ProgressBarContainer.propTypes = {
  articleRef: PropTypes.func.isRequired,
};

const fetcher = (query) => fetchViaApi(query).then((entry) => entry?.data?.ctaBlock);

const CTA_BLOCK_FALLBACK_ID = '4ey3AirtwTUc3Nw21Z5lrV';

const Article = ({ type, article }) => {
  const theme = useTheme();
  const articleRef = useRef(null);
  const query = ctaBlockById(CTA_BLOCK_FALLBACK_ID);
  const { data: ctaBlockFallback, error: ctaBlockFallbackError } = useSWR(query, fetcher);

  // strip the timezone to get the actual date we want
  const formattedDate = format(
    new Date(article.lastReviewedDate.substring(0, article.lastReviewedDate.length - 1)),
    'MMM dd yyyy'
  );

  const toc = useMemo(() => {
    if (!article.showTableOfContents) return null;

    return article.bodyText?.content?.json?.content
      .filter((node) => node.nodeType === 'heading-2')
      .map((node) => ({
        label: node.content[0]?.value,
        id: generateHtmlIdFromContent(node.content[0]?.value),
      }));
  }, [article]);

  return (
    <>
      {/* <ProgressBarContainer articleRef={articleRef} /> */}
      <Flex width={1} maxWidth="120rem" mx="auto" mb={7}>
        {article.showTableOfContents && (
          <DesktopTocWrapper>
            <DesktopToc type={type} as="aside">
              <ThemeProvider theme={themeV2}>
                <TableOfContents
                  // prevents state from being preserved on client-side transitions
                  key={article.sys.id}
                  items={toc}
                  data-testid="tocToggleDesktop"
                  baseId="article_toc_desktop"
                />
                {article.sidebarCta && (
                  <CtaBlock variant="article" mt={4} {...article.sidebarCta} />
                )}
                {!article.sidebarCta && ctaBlockFallback && !ctaBlockFallbackError && (
                  <CtaBlock variant="article" mt={4} {...ctaBlockFallback} />
                )}
              </ThemeProvider>
            </DesktopToc>
          </DesktopTocWrapper>
        )}
        <ArticleContainer
          showTableOfContents={article.showTableOfContents}
          ref={articleRef}
        >
          <ArticleWrapper showTableOfContents={article.showTableOfContents}>
            <StyledHeading>
              <Heading
                as="h1"
                variant={['h4', null, null, 'h2']}
                mt={0}
                fontWeight={700}
                textAlign="center"
              >
                {article.title}
              </Heading>
            </StyledHeading>
            {type === 'blog' && (
              <FeaturedImage
                src={getContentfulImgixUrl(article.image?.url)}
                width={800}
                additionalParams={{ fit: 'crop', ar: '79:29' }}
                lazyload
              />
            )}
            <StyledArticle>
              <EyebrowText>
                <span>LAST REVIEWED {formattedDate}</span>{' '}
                <StyledCircleOne fill={theme.colors.gray90} size={12} />
                <StyledFlex>
                  <span>{article.readingLength} MIN READ</span>
                  <StyledCircleTwo fill={theme.colors.gray90} size={12} />
                  <EditorialLink
                    href="/legal/editorial-policy/"
                    target="_blank"
                    rel="noopener"
                  >
                    Editorial Policy
                  </EditorialLink>
                </StyledFlex>
              </EyebrowText>
              {type === 'learningCenter' ? (
                <ByLineText>
                  By{' '}
                  <AuthorTooltip name={article.author.name} slug={article.author.slug} />
                </ByLineText>
              ) : (
                <ByLineText>By {article.author?.name}</ByLineText>
              )}
              <SocialSharingButtons socialSharingOptions={article.socialSharingOptions} />
              {article.showTableOfContents && (
                <MobileToc>
                  <ThemeProvider theme={themeV2}>
                    <TableOfContents
                      items={toc}
                      data-testid="tocToggleMobile"
                      baseId="article_toc_mobile"
                    />
                  </ThemeProvider>
                </MobileToc>
              )}
              <ArticleBody my={6}>
                {article.keyTakeaways && (
                  <KeyTakeaways items={article.keyTakeaways.itemsCollection?.items} />
                )}
                <ContentfulRichText
                  content={article.bodyText?.content?.json}
                  links={article.bodyText?.content?.links}
                  renderOptions={renderOptions}
                />
              </ArticleBody>
              <Divider variant="thick" my={4} />
              <ArticleAuthorCard author={article.author} />
              <Divider variant="thick" my={4} />
            </StyledArticle>
          </ArticleWrapper>
        </ArticleContainer>
      </Flex>
    </>
  );
};

Article.propTypes = {
  type: PropTypes.oneOf(['blog', 'learningCenter']).isRequired,
  article: contentfulLearningCenterArticleType.isRequired,
};

export default Article;
