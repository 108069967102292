import PropTypes from 'prop-types';

import { contentfulImageType } from 'types/common/contentfulImage.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';

export const authorCardProps = {
  name: PropTypes.string.isRequired,
  authorRole: PropTypes.string.isRequired,
  image: contentfulImageType.isRequired,
  bio: simpleRichTextType,
  description: PropTypes.string,
  twitter: PropTypes.string,
  email: PropTypes.string,
  linkedIn: PropTypes.string,
  variant: PropTypes.oneOf(['tooltip', 'page']),
};

export const authorCardDefaultProps = {
  bio: null,
  description: null,
  twitter: null,
  email: null,
  linkedIn: null,
  variant: 'page',
};

export const authorCardType = PropTypes.shape({});
