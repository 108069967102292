import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';

import { simpleRichTextType } from 'types';
import Box from 'legacy/components/box';
import Text from 'legacy/components/text';
import SimpleRichText from 'legacy/components/humanity-v1/primitives/simpleRichText';

const StyledBox = styled(Box)(
  css({
    mb: 4,
    p: 4,
    bg: '#F7F9FC',
    borderRadius: 'large',
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: 'gray90',
  })
);

const Title = styled(Text)(
  css({
    mt: 0,
    fontWeight: 700,
    color: 'blue',
    textTransform: 'uppercase',
  })
);

const List = styled('ul')(
  css({
    pl: 3,
  })
);

const ListItem = styled('li')(
  css({
    mt: 2,
    listStyleType: 'disc',
    '&::marker': {
      color: 'gray30',
    },
  })
);

const KeyTakeaways = ({ items }) => (
  <StyledBox>
    <Title>Key Takeaways</Title>
    <List>
      {items.map((item) => (
        <ListItem key={item.sys?.id}>
          <SimpleRichText content={item.content?.json} mt={0} />
        </ListItem>
      ))}
    </List>
  </StyledBox>
);

KeyTakeaways.propTypes = {
  items: PropTypes.arrayOf(simpleRichTextType).isRequired,
};

export default KeyTakeaways;
