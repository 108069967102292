import PropTypes from 'prop-types';

import Box from 'humanity/primitives/box';

/**
 * Use to hide labels and other content meant for screenreaders only
 */
const VisuallyHidden = ({ as, ...props }) => (
  <Box
    as={as}
    sx={{
      border: '0',
      clip: 'rect(1px, 1px, 1px, 1px)',
      clipPath: 'inset(50%)',
      height: '1px',
      overflow: 'hidden',
      margin: '-1px',
      padding: '0',
      position: 'absolute',
      width: '1px',
      whiteSpace: 'nowrap',
    }}
    {...props}
  />
);

VisuallyHidden.propTypes = {
  as: PropTypes.elementType,
};

VisuallyHidden.defaultProps = {
  as: 'span',
};

export default VisuallyHidden;
