import { forwardRef } from 'react';

import { iconDefaultProps, iconProps } from 'humanity/icons/index.types';

const Twitter = forwardRef(({ color, size, ...props }, ref) => (
  <svg
    ref={ref}
    width={size}
    height={(14 / 16) * parseInt(size, 10)}
    viewBox="-6 -4 24 21"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5208 4.00501L7.55438 4.55864L6.99479 4.49085C4.95791 4.23098 3.17843 3.34968 1.66756 1.86955L0.928908 1.13514L0.73865 1.67748C0.33575 2.88644 0.593158 4.16319 1.43253 5.02189C1.8802 5.49643 1.77948 5.56423 1.00725 5.28176C0.73865 5.19137 0.503625 5.12358 0.481242 5.15747C0.4029 5.23656 0.6715 6.26475 0.884142 6.6715C1.17513 7.23643 1.76828 7.79007 2.4174 8.11773L2.96579 8.3776L2.31668 8.3889C1.68994 8.3889 1.66756 8.4002 1.73471 8.63747C1.95854 9.37189 2.84268 10.1515 3.82755 10.4905L4.52143 10.7277L3.91708 11.0893C3.02175 11.609 1.96973 11.9028 0.917717 11.9254C0.414092 11.9367 0 11.9819 0 12.0158C0 12.1288 1.36538 12.7615 2.15999 13.0101C4.54382 13.7445 7.37531 13.4281 9.50173 12.174C11.0126 11.2814 12.5235 9.50747 13.2286 7.79007C13.6091 6.87488 13.9896 5.20267 13.9896 4.40046C13.9896 3.88072 14.0232 3.81293 14.6499 3.1915C15.0192 2.82994 15.3662 2.43449 15.4333 2.3215C15.5452 2.10683 15.534 2.10683 14.9633 2.2989C14.012 2.63786 13.8777 2.59267 14.3477 2.08423C14.6947 1.72267 15.1088 1.06735 15.1088 0.875268C15.1088 0.841372 14.9409 0.897865 14.7506 0.999553C14.5492 1.11254 14.1015 1.28202 13.7658 1.38371L13.1614 1.57579L12.613 1.20293C12.3108 0.999553 11.8856 0.77358 11.6617 0.705787C11.0909 0.547606 10.218 0.570203 9.70318 0.750982C8.30422 1.25942 7.42008 2.57007 7.5208 4.00501Z"
    />
  </svg>
));

Twitter.displayName = 'Twitter';

Twitter.propTypes = {
  ...iconProps,
};

Twitter.defaultProps = {
  ...iconDefaultProps,
};

export default Twitter;
