import { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import css from '@styled-system/css';
import { Cross } from 'react-dripicons';

import Box from 'humanity/primitives/box';
import Card from 'humanity/components/card';
import { modalProps } from 'humanity/components/modal/modal.types';

const StyledModal = styled(Box)(({ isOpen }) =>
  css({
    display: isOpen ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.25)',
    // HeaderNav uses a z-index of 500
    zIndex: 600,
  })
);

const Overlay = styled(Box)(
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  })
);

const Content = styled(Card)(
  css({
    position: 'relative',
    mt: [0, null, -10],
    overflow: 'auto',
    zIndex: 1000,
  })
);

const CloseIcon = styled.button(
  css({
    position: 'absolute',
    top: [2, null, 3],
    right: [2, null, 3],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    m: 0,
    p: 0,
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    appearance: 'none',
    '& > svg': {
      color: 'gray50',
      '&:hover': {
        color: 'gray30',
      },
    },
  })
);

const Modal = ({
  isOpen = false,
  onClose = () => {},
  contentLabel = '',
  children,
  ...props
}) => {
  const theme = useTheme();
  useEffect(() => {
    const handleEscPress = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscPress);

    return () => window.removeEventListener('keydown', handleEscPress);
  }, [onClose]);

  return (
    <StyledModal data-testid="Modal" isOpen={isOpen}>
      <Overlay data-testid="ModalOverlay" isOpen={isOpen} onClick={onClose} />
      <Content
        data-testid="ModalContent"
        isOpen={isOpen}
        role="dialog"
        aria-label={contentLabel}
        aria-modal="true"
        {...props}
      >
        <CloseIcon aria-label="close" onClick={onClose}>
          <Cross size={24} color={theme.colors.blue100} />
        </CloseIcon>
        <Box width={1} height="100%">
          {children}
        </Box>
      </Content>
    </StyledModal>
  );
};

Modal.propTypes = {
  ...modalProps,
};

export default Modal;
