import styled from 'styled-components';
import css from '@styled-system/css';
import { variant as styledVariant } from 'styled-system';
import { Mail } from 'react-dripicons';

import {
  authorCardDefaultProps,
  authorCardProps,
} from 'humanity/components/authorCard/authorCard.types';
import Box from 'humanity/primitives/box';
import Flex from 'humanity/primitives/flex';
import Heading from 'humanity/primitives/heading';
import Image from 'humanity/primitives/image';
import Text from 'humanity/primitives/text';
import SocialLink from 'humanity/components/socialLink';
import Twitter from 'humanity/icons/twitter';
import LinkedIn from 'humanity/icons/linkedIn';
import SimpleRichText from 'humanity/components/simpleRichText';

const Info = styled(Flex)(
  styledVariant({
    variants: {
      tooltip: {
        gap: 3,
      },
      page: {
        flexWrap: ['wrap', 'nowrap'],
        gap: [3, 5],
      },
    },
  })
);

const Avatar = styled(Box)(
  css({
    flexShrink: 0,
    borderRadius: 'circle',
    overflow: 'hidden',
    lineHeight: 0,
  }),
  styledVariant({
    variants: {
      tooltip: {
        width: 66,
        height: 66,
      },
      page: {
        width: 174,
        height: 174,
      },
    },
  })
);

const Icons = styled(Flex)(
  css({
    gap: 5,
    mt: 3,
  })
);

const variants = {
  tooltip: {
    image: 66,
    heading: 'h4',
    content: 4,
  },
  page: {
    image: 174,
    heading: ['h4', null, null, 'h3'],
    content: 5,
  },
};

const AuthorCard = ({
  name,
  authorRole,
  image,
  bio,
  description,
  twitter,
  email,
  linkedIn,
  variant,
  ...props
}) => (
  <Box {...props}>
    <Info variant={variant}>
      <Avatar variant={variant}>
        <Image
          src={image.image?.url}
          width={variants[variant]?.image}
          height={variants[variant]?.image}
          imgixParams={{ fit: 'crop', ar: '1:1' }}
          alt={`Image of ${name}`}
        />
      </Avatar>
      <Box
        alignSelf={!authorRole?.length && variant === 'tooltip' ? 'center' : 'flex-start'}
      >
        <Heading as="h1" variant={variants[variant]?.heading}>
          {name}
        </Heading>
        {!!authorRole?.length && <Text variant="body">{authorRole}</Text>}
        {variant === 'page' && (
          <Icons>
            {!!twitter?.length && (
              <SocialLink Icon={Twitter} title="Twitter" href={twitter} />
            )}
            {!!email?.length && (
              <SocialLink Icon={Mail} title="Email" href={`mailto:${email}`} />
            )}
            {!!linkedIn?.length && (
              <SocialLink Icon={LinkedIn} title="LinkedIn" href={linkedIn} />
            )}
          </Icons>
        )}
      </Box>
    </Info>
    <Box mt={variants[variant]?.content}>
      {variant === 'page' && <SimpleRichText content={bio?.json} />}
      {variant === 'tooltip' && <Text variant="body">{description}</Text>}
    </Box>
  </Box>
);

AuthorCard.propTypes = {
  ...authorCardProps,
};

AuthorCard.defaultProps = {
  ...authorCardDefaultProps,
};

export default AuthorCard;
