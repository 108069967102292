import PropTypes from 'prop-types';

export const tableOfContentsProps = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  activeItemIndex: PropTypes.number,
};

export const tableOfContentsDefaultProps = {
  activeItemIndex: 0,
};

export const tableOfContentsType = PropTypes.shape({ ...tableOfContentsProps });
