import PropTypes from 'prop-types';
import styled from 'styled-components';
import { css } from '@styled-system/css';

import Image from 'legacy/components/image';
import Text from 'legacy/components/text';
import Card from 'legacy/components/card';
import Link from 'legacy/components/link';
import getContentfulImgixUrl from 'utils/getContentfulImgixUrl';
import Box from 'legacy/components/box';
import { contentfulImageType } from 'types/common/contentfulImage.types';

const HeadingLogo = styled(Box)(
  css({
    width: '50px',
    height: '50px',
    borderRadius: 'circle',
    overflow: 'hidden',
  })
);

const HeadingText = styled(Text)(
  css({
    my: 0,
    color: 'blue',
    fontWeight: 600,
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  })
);

const AuthorLink = styled(Link)(
  css({
    my: 0,
    color: 'blue',
    fontWeight: 700,
    letterSpacing: '0.05em',
  })
);

const AuthorText = styled(Text)(
  css({
    my: 0,
    color: 'blue',
    fontWeight: 700,
    letterSpacing: '0.05em',
  })
);

const ArticleAuthorCard = ({ author }) => (
  <Card my={6} bg="white">
    <Box display="flex">
      <HeadingLogo>
        <Image
          src={getContentfulImgixUrl(author.image?.image?.url)}
          width={50}
          height={50}
          htmlAttributes={{ alt: author.name }}
          additionalParams={{ fit: 'crop', ar: '1:1' }}
          lazyload
        />
      </HeadingLogo>
      <Box display="flex" flexDirection="column" ml="1.5rem">
        <HeadingText>Article By</HeadingText>
        {author.slug ? (
          <AuthorLink href={`/learn/contributors/${author.slug}/`}>
            {author.name}
          </AuthorLink>
        ) : (
          <AuthorText>{author.name}</AuthorText>
        )}
      </Box>
    </Box>
    <Text lineHeight={2}>{author.description}</Text>
  </Card>
);

ArticleAuthorCard.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: contentfulImageType.isRequired,
    slug: PropTypes.string,
  }).isRequired,
};

export default ArticleAuthorCard;
