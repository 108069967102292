import PropTypes from 'prop-types';

import { imageTextBlockProps } from 'humanity/components/imageTextBlock/imageTextBlock.types';
import { contentfulRichTextType } from 'types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

export const imageTextBlockSectionProps = {
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string,
  description: contentfulRichTextType,
  imageTextBlocks: PropTypes.arrayOf(imageTextBlockProps),
};

export const imageTextBlockSectionType = PropTypes.shape(imageTextBlockSectionProps);
