import { imageTextBlockProps } from 'humanity/components/imageTextBlock/imageTextBlock.types.js';
import Box from 'humanity/primitives/box';
import Image from 'humanity/primitives/image';
import Text from 'humanity/primitives/text';

const ImageTextBlock = ({ title, description, image }) => {
  const { height, width, altText } = image;
  const { url } = image.image;
  return (
    <Box textAlign="center">
      <Image src={url} height={height} width={width} alt={altText} />
      <Text fontWeight="600">{title}</Text>
      <Text>{description}</Text>
    </Box>
  );
};

ImageTextBlock.propTypes = {
  ...imageTextBlockProps,
};

export default ImageTextBlock;
