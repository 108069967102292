import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulImageType } from 'types/common/contentfulImage.types';

export const profileCardProps = {
  image: contentfulImageType.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  twitter: PropTypes.string,
  email: PropTypes.string,
  linkedIn: PropTypes.string,
  bio: simpleRichTextType,
};

export const profileProps = {
  hasBg: PropTypes.bool,
  handleClick: PropTypes.func,
  ...profileCardProps,
};

export const profileCardType = PropTypes.shape({ ...profileProps });
