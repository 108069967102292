/**
 * @type {String}
 */
export const imageTextBlockSectionFragment = `
  ... on SectionImageTextBlocks {
      heading
      body {
        json
      }
      imageTextBlocksCollection(limit: 10) {
        items {
          title
          description
          image {
            altText
            width
            height
            image {
              url
            }
          }
        }
      }
    }
`;
