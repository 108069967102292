export const profileCardStandard = {
  sys: {
    id: '3XVINP33jdEwN1I8zJ01j4',
  },
  name: 'Example Author',
  role: 'Marketing Coordinator at Johnson & Johnson',
  image: {
    image: {
      url: 'https://images.ctfassets.net/tj9jxg7kaxby/5sUSl1qTvES2Wf1YC5NqbX/283431db581dcf6797a767ed115efc29/Lucy.JPG',
    },
  },
  bio: {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Vicki Waun, QPA, QKC, QKA, CMFC, CRPS, CEBS, is a Senior Legal Product Analyst at ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.example.com',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Human Interest',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value:
                ' and has over 20 years experience with recordkeeping qualified plans, along with extensive experience in compliance testing. She earned her BSBA in Accounting from Old Dominion University and is a member of ASPPA.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Odio aenean sed adipiscing diam donec. Ridiculus mus mauris vitae ultricies leo integer malesuada nunc. Euismod elementum nisi quis eleifend. In ornare quam viverra orci sagittis eu volutpat odio. Ultricies mi eget mauris pharetra. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci a. Lectus magna fringilla urna porttitor rhoncus. Purus non enim praesent elementum facilisis leo vel fringilla est. Orci eu lobortis elementum nibh tellus molestie nunc. Pellentesque habitant morbi tristique senectus. Neque egestas congue quisque egestas diam in.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  },
  description:
    'Vicki Waun, QPA, QKC, QKA, CMFC, CRPS, CEBS, is a Senior Legal Product Analyst at Human Interest and has over 20 years experience with recordkeeping qualified plans, along with extensive experience in compliance testing. She earned her BSBA in Accounting from Old Dominion University and is a member of ASPPA.',
  twitter: 'https://twitter.com/BarackObama/',
  email: 'example.author@example.com',
  linkedIn: 'https://www.linkedin.com/in/joemarchese',
};
