import styled, { useTheme } from 'styled-components';
import css from '@styled-system/css';
import { Mail } from 'react-dripicons';
import { border, color, space, typography } from 'styled-system';
import { useState } from 'react';

import {
  profileCardProps,
  profileProps,
} from 'humanity/components/profileCard/profileCard.types.js';
import Image from 'humanity/primitives/image';
import Flex from 'humanity/primitives/flex';
import SocialLink from 'humanity/components/socialLink';
import Twitter from 'humanity/icons/twitter';
import LinkedIn from 'humanity/icons/linkedIn';
import SimpleRichText from 'humanity/components/simpleRichText';
import Modal from 'humanity/components/modal';
import Heading from 'humanity/primitives/heading';

const Card = styled(Flex)(typography, space, border, color);

const Icons = styled(Flex)(
  css({
    gap: 2,
    mt: 2,
  })
);

const Profile = ({
  handleClick,
  hasBg,
  name,
  role,
  image,
  twitter = null,
  email = null,
  linkedIn = null,
}) => {
  const theme = useTheme();

  return (
    <Card
      p={hasBg ? 24 : 0}
      pb={24}
      backgroundColor={hasBg ? theme.colors.backgroundLightGray2 : 'transparent'}
      borderRadius={20}
      gap={3}
      width="100%"
      maxWidth={[null, null, '100%', '378px']}
      onClick={handleClick}
      style={{ cursor: handleClick ? 'pointer' : 'auto' }}
    >
      <Image
        src={image.image?.url}
        width={120}
        height={120}
        imgixParams={{ fit: 'crop', ar: '1:1' }}
        alt={`Image of ${name}`}
        style={{ backgroundColor: '#EFF1F4', borderRadius: '20px' }}
      />
      <div>
        <Heading variant="h6">{name}</Heading>
        <div>{role}</div>
        <div>
          <Icons>
            {!!linkedIn?.length && (
              <SocialLink
                size={24}
                Icon={LinkedIn}
                title="LinkedIn"
                href={linkedIn}
                bg={theme.colors.gray20}
                bgHover={theme.colors.gray30}
                iconColor="#969BAB"
              />
            )}
            {!!twitter?.length && (
              <SocialLink
                size={24}
                Icon={Twitter}
                title="Twitter"
                href={twitter}
                bg={theme.colors.gray20}
                bgHover={theme.colors.gray30}
                iconColor="#969BAB"
              />
            )}
            {!!email?.length && (
              <SocialLink
                size={24}
                Icon={Mail}
                title="Email"
                href={`mailto:${email}`}
                bg={theme.colors.gray20}
                bgHover={theme.colors.gray30}
                iconColor="#969BAB"
              />
            )}
          </Icons>
        </div>
      </div>
    </Card>
  );
};
Profile.propTypes = profileProps;

const ProfileCard = ({
  name,
  role,
  image,
  twitter = null,
  email = null,
  linkedIn = null,
  bio,
  props,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Profile
        hasBg
        handleClick={() => setIsModalOpen(!isModalOpen)}
        name={name}
        role={role}
        image={image}
        twitter={twitter}
        email={email}
        linkedIn={linkedIn}
        style={{ width: '379px' }}
        {...props}
      />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        contentLabel="Profile modal window"
        maxWidth={600}
        p={56}
      >
        <Profile
          name={name}
          role={role}
          image={image}
          twitter={twitter}
          email={email}
          linkedIn={linkedIn}
        />
        {bio && bio.json && <SimpleRichText content={bio?.json} />}
      </Modal>
    </>
  );
};

ProfileCard.propTypes = {
  ...profileCardProps,
};

export default ProfileCard;
