import { forwardRef } from 'react';

import { iconDefaultProps, iconProps } from 'humanity/icons/index.types';

const LinkedIn = forwardRef(({ color, size, ...props }, ref) => (
  <svg
    ref={ref}
    width={size}
    height={size}
    viewBox="-5 -3 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.54585 1.8818C4.52232 0.813602 3.75844 0 2.51803 0C1.27761 0 0.466675 0.813602 0.466675 1.8818C0.466675 2.92787 1.25365 3.7649 2.47096 3.7649H2.49413C3.75844 3.7649 4.54585 2.92787 4.54585 1.8818Z" />
    <path d="M4.30704 5.25195H0.681091V16.1464H4.30704V5.25195Z" />
    <path d="M13.2088 4.99609C15.5948 4.99609 17.3836 6.55352 17.3836 9.89983L17.3835 16.1464H13.7577V10.3178C13.7577 8.85384 13.233 7.85484 11.9204 7.85484C10.9186 7.85484 10.3219 8.52831 10.0599 9.17878C9.964 9.4119 9.94047 9.73671 9.94047 10.0623V16.1467H6.31409C6.31409 16.1467 6.36188 6.27449 6.31409 5.25221H9.94047V6.79532C10.4217 6.05352 11.2836 4.99609 13.2088 4.99609Z" />
  </svg>
));

LinkedIn.displayName = 'LinkedIn';

LinkedIn.propTypes = {
  ...iconProps,
};

LinkedIn.defaultProps = {
  ...iconDefaultProps,
};

export default LinkedIn;
