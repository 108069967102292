import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';
import { Facebook, Linkedin, Mail, Twitter } from 'react-feather';
import { useRouter } from 'next/router';

import { contentfulSocialSharingType } from 'types';
import { track } from 'utils/analytics';
import Card from 'legacy/components/card';
import Link from 'legacy/components/link';

const StyledSharingButtons = styled(Card)(
  css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    mt: 3,
    background: 'transparent',
    ml: '-1.5rem',
  })
);

const Circle = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    backgroundColor: 'teal',
    ml: '1.5rem',
  })
);

const SocialSharingButtons = ({ socialSharingOptions, ...props }) => {
  const router = useRouter();

  const pageUrl = `${process.env.NEXT_PUBLIC_HI_BASE_URL}${router.asPath}`;
  const { twitterHashtags, emailSubject, twitterText, emailBody } = socialSharingOptions;

  const handleClick = (shareType) => track(`${shareType}_share`);

  const handleDown = (shareType, e) => {
    if (e.button > 0) {
      let mouseButton;

      switch (e.button) {
        case 1:
          mouseButton = 'middle_mouse';
          break;
        case 2:
          mouseButton = 'right_mouse';
          break;
        default:
          mouseButton = 'mouse';
      }

      track(`${shareType}_share`, {
        mouse_button: mouseButton,
      });
    }
  };

  return (
    <StyledSharingButtons {...props} data-testid="SocialSharingButtons">
      <Link
        title="Share to Facebook"
        external
        href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`}
        target="_blank"
        rel="noreferrer"
        data-testid="FacebookSocialShare"
        onClick={() => handleClick('facebook')}
        onMouseDown={(e) => handleDown('facebook', e)}
      >
        <Circle>
          <Facebook fill="white" strokeWidth={1} size={18} color="white" />
        </Circle>
      </Link>
      <Link
        title="Share to Twitter"
        external
        href={`https://twitter.com/intent/tweet?text=${twitterText}&via=humaninteresthq&hashtags=${
          twitterHashtags ? twitterHashtags.join() : ''
        }&url=${pageUrl}`}
        data-testid="TwitterSocialShare"
        onClick={() => handleClick('twitter')}
        onMouseDown={(e) => handleDown('twitter', e)}
      >
        <Circle>
          <Twitter fill="white" strokeWidth={1} size={18} color="white" />
        </Circle>
      </Link>
      <Link
        title="Share to LinkedIn"
        external
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
        data-testid="LinkedinSocialShare"
        onClick={() => handleClick('linkedin')}
        onMouseDown={(e) => handleDown('linkedin', e)}
      >
        <Circle>
          <Linkedin fill="white" strokeWidth={1} size={18} color="white" />
        </Circle>
      </Link>
      <Link
        title="Share via Email"
        external
        href={`mailto:?subject=${emailSubject}&body=${emailBody}%0A%0A${pageUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="EmailSocialShare"
        onClick={() => handleClick('email')}
        onMouseDown={(e) => handleDown('email', e)}
      >
        <Circle>
          <Mail size={20} color="white" />
        </Circle>
      </Link>
    </StyledSharingButtons>
  );
};

SocialSharingButtons.propTypes = {
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  socialSharingOptions: contentfulSocialSharingType.isRequired,
};

SocialSharingButtons.defaultProps = {
  alignment: 'center',
};

export default SocialSharingButtons;
