import { authorTooltipFragment } from 'humanity/components/authorTooltip/authorTooltip.fragment';

export const authorTooltipBySlug = (slug, preview = false) => `
  query {
    authorCollection( where: { slug: "${slug}" }, limit: 1, preview: ${preview}) {
      items {
        ${authorTooltipFragment}
      }
    }
  }
`;
