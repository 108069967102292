export const imageTextBlockSectionStandard = {
  heading: 'Image Text Blocks',
  body: {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Fun stuff',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  },
  imageTextBlocksCollection: {
    items: [
      {
        title: 'First block',
        description: 'Description one',
        image: {
          height: 113,
          width: 113,
          altText: 'TrustRadius Top Rated 2023 Badge',
          image: {
            url: 'https://images.ctfassets.net/tj9jxg7kaxby/2JhzO3Ir4IRKFqDBRSreNV/877f8e1b52d6a22889f93ccce6b5b641/top-rated-2023-3dgradient_1.png',
          },
        },
      },
      {
        title: 'Trust Radius',
        description: 'Top Rated 2023',
        image: {
          height: 113,
          width: 113,
          altText: 'TrustRadius Top Rated 2023 Badge',
          image: {
            url: 'https://images.ctfassets.net/tj9jxg7kaxby/2JhzO3Ir4IRKFqDBRSreNV/877f8e1b52d6a22889f93ccce6b5b641/top-rated-2023-3dgradient_1.png',
          },
        },
      },
    ],
  },
};
