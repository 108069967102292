export const profileCardsSectionStandard = {
  heading: 'Meet our team',
  topPadding: 128,
  bottomPadding: 128,
  backgroundColor: '#ffffff',
  body: {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We have a diverse team of smart, motivated, fun people who are committed to solving America’s retirement savings crisis.\nOur leadership includes some of the most inventive and experienced executives from tech, finance, and retirement benefits.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  },
  authorsCollection: {
    items: [
      {
        name: 'Jeff Schneble',
        bio: {
          json: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value:
                      "CEO, Human Interest. \nWe're on a mission to ensure that people in all lines of work have access to retirement benefits.",
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
        },
        role: null,
        linkedIn: 'http://www.linkedin.com/in/jeffschneble',
        twitter: 'http://www.twitter.com/jeffschneble',
        email: 'jeff@humaninterest.com',
        image: {
          altText: 'Jeff Schneble',
          width: null,
          height: null,
          image: {
            url: 'https://images.ctfassets.net/tj9jxg7kaxby/5toy2lcoOYrVKALHCbkF6u/41f79798258241472b21a6ae90220a62/Jeff-Schneble.png',
          },
        },
      },
      {
        name: 'Mike Armsby',
        bio: {
          json: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'test',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        role: 'Chief Financial Officer',
        linkedIn: null,
        twitter: null,
        email: null,
        image: {
          altText: 'Mike Armsby photo',
          width: null,
          height: null,
          image: {
            url: 'https://images.ctfassets.net/tj9jxg7kaxby/1RsT8HDdouMThI7EXtv3XC/001a241c0c2dd258331cd5a87b149e16/mike.jpeg',
          },
        },
      },
      {
        name: 'Abhi Mishra',
        bio: {
          json: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'test',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
        },
        role: 'Chief Technology Officer',
        linkedIn: null,
        twitter: null,
        email: null,
        image: {
          altText: 'Abhi Mishra headshot',
          width: null,
          height: null,
          image: {
            url: 'https://images.ctfassets.net/tj9jxg7kaxby/2mZfL0AHeQhTkLrYIn6Y2m/7a467c0652c83dccc062c0c493718e57/IMG_8450.jpeg',
          },
        },
      },
      {
        name: 'Rakesh Mahajan',
        bio: {
          json: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'test',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
        },
        role: 'Chief Revenue Officer',
        linkedIn: null,
        twitter: null,
        email: null,
        image: {
          altText: 'Rakesh Mahajan headshot',
          width: null,
          height: null,
          image: {
            url: 'https://images.ctfassets.net/tj9jxg7kaxby/5E6GUN8lBdZQtggoY8HCCT/e5ab68f5bff5ca923fe775b4f48a5635/Rakesh-Mahajan.png',
          },
        },
      },
      {
        name: 'Kristina Wallender',
        bio: {
          json: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'test',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
        },
        role: 'Chief. Experience Officer',
        linkedIn: null,
        twitter: null,
        email: null,
        image: {
          altText: 'Kristina Wallender headshot',
          width: null,
          height: null,
          image: {
            url: 'https://images.ctfassets.net/tj9jxg7kaxby/24CYg6rdF8j1mKAhams1RG/47e644c7ff3fb168a961553311c0c533/Kristina-Wallender.jpg',
          },
        },
      },
      {
        name: 'Klinton Miyao',
        bio: null,
        role: 'General Counsel & Chief People Officer',
        linkedIn: null,
        twitter: null,
        email: null,
        image: {
          altText: 'Klinton Miyao headshot',
          width: null,
          height: null,
          image: {
            url: 'https://images.ctfassets.net/tj9jxg7kaxby/6RRIe8x5ycpgd80h63WYag/a005c31d4a2cb6d6a357647a9c2915d6/KSM_Photo.jpg',
          },
        },
      },
      {
        name: 'Yagnesh Patel',
        bio: {
          json: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'test',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
        },
        role: 'Chief Accounting Officer',
        linkedIn: null,
        twitter: null,
        email: null,
        image: {
          altText: 'Yagnesh Patel headshot',
          width: null,
          height: null,
          image: {
            url: 'https://images.ctfassets.net/tj9jxg7kaxby/6DZuGCPl60MEvCI9q5oE8W/8186553f2922e536ddf2a5eab171d5cc/Yagnesh.jpeg',
          },
        },
      },
    ],
  },
};
