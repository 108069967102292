/**
 * @type {String}
 */
export const profileCardsSectionFragment = `
  ... on SectionProfileCards {
      topPadding
      bottomPadding
      backgroundColor
      heading
      body {
        json
      }
      authorsCollection(limit: 20) {
        items {
          ... on Author {
            name
            image {
              altText
              width
              height
              image {
                url
              }
            }
            bio {
              json
            }
            role
            linkedIn
            twitter
            email
          }
        }
      }
    }
`;
