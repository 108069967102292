import PropTypes from 'prop-types';

export const iconProps = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const iconDefaultProps = {
  // using a theme value here would be better for refactoring, but don't really
  // want to import the entire file for one value
  color: '#022A4D',
  size: 24,
};
