import { useEffect, useState } from 'react';

const useProgressScrollBar = (scrollElementRef) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const calculateScrollDistance = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const docHeight = scrollElementRef.current?.scrollHeight;

      const totalDocScrollLength = docHeight - windowHeight;
      const newScrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100);
      setScrollPosition(newScrollPosition);
    };

    const handleScroll = () => {
      requestAnimationFrame(() => {
        calculateScrollDistance();
      });
    };

    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [scrollElementRef, scrollPosition]);

  return scrollPosition;
};

export default useProgressScrollBar;
