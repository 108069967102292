/**
 * @type {String}
 */
export const authorTooltipFragment = `
  ... on Author {
    sys {
      id
    }
    name
    slug
    role
    image {
      image {
        url
      }
    }
    description
  }
`;
