import PropTypes from 'prop-types';

import { ctaType } from 'humanity/components/cta/cta.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

const commonProps = {
  backgroundColor: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  cta: ctaType.isRequired,
};

export const ctaBlockProps = {
  ...commonProps,
  variant: PropTypes.oneOf(['headerNav', 'article']),
};

export const ctaBlockDefaultProps = {
  paragraph: null,
  variant: 'headerNav',
};

export const ctaBlockType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...commonProps,
});
