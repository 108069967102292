import styled from 'styled-components';
import css from '@styled-system/css';
import PropTypes from 'prop-types';

import Box from 'legacy/components/box';

const Container = styled(Box)(
  css({
    width: '100%',
    height: '0.25rem',
    bg: 'gray90',
  })
);

const Bar = styled(Box)(
  css({
    height: '100%',
    transition: 'width 0.5s ease-in-out',
    'animation-fill-mode': 'both',
  })
);

const ProgressBar = ({ progress, barColor, ...props }) => (
  <Container {...props}>
    <Bar style={{ width: `${progress}%` }} bg={barColor} />
  </Container>
);

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  barColor: PropTypes.string,
};

ProgressBar.defaultProps = {
  barColor: 'teal',
};

export default ProgressBar;
