import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';

import Accordion from 'humanity/components/accordion';
import {
  tableOfContentsDefaultProps,
  tableOfContentsProps,
} from 'humanity/components/tableOfContents/tableOfContents.types.js';
import Box from 'humanity/primitives/box';
import Flex from 'humanity/primitives/flex';
import Text from 'humanity/primitives/text';

const StyledTocItem = styled(Flex)(
  css({
    position: 'relative',
    alignItems: 'center',
    pt: 2,
    '&:first-child': {
      pt: 0,
    },
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      left: '4px',
      width: '2px',
      bg: 'gray20',
    },
    '&:not(:first-child)': {
      '&::before': {
        top: 0,
        bottom: '50%',
      },
    },
    '&:not(:last-child)': {
      '&::after': {
        top: '50%',
        bottom: 0,
      },
    },
  })
);

const ProgressMarker = styled(Box)(({ isActive }) =>
  css({
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: isActive ? 'teal100' : 'gray20',
    borderRadius: 'circle',
    bg: isActive ? 'teal100' : 'white',
    width: 10,
    height: 10,
    flexShrink: 0,
    zIndex: 5,
    transition: isActive ? 'all 0.3s ease-in-out 0.1s' : 'all 0.3s ease-in-out',
  })
);

const TocLink = styled(Box)(({ isActive }) =>
  css({
    fontSize: 'label',
    fontWeight: isActive ? 'bold' : 'body',
    lineHeight: 'label',
    color: isActive ? 'tealDark' : 'blue100',
    textDecoration: 'none',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      textDecoration: 'underline',
    },
  })
);

const TocItem = ({ item, isActive, onActivated, ...rest }) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current = document.getElementById(item.id);
  }, [item.id]);

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        const topOffset = ref?.current?.getBoundingClientRect().top;

        if (topOffset && topOffset <= 1 && typeof onActivated === 'function') {
          onActivated();
        }
      });
    };

    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [item.id, onActivated, ref]);

  return (
    <StyledTocItem as="li" isActive={isActive} {...rest}>
      <ProgressMarker isActive={isActive} />
      <Box ml={3}>
        <TocLink as="a" href={`#${item.id}`} isActive={isActive}>
          {item.label}
        </TocLink>
      </Box>
    </StyledTocItem>
  );
};

TocItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  onActivated: PropTypes.func.isRequired,
};

const TocWrapper = styled(Box)(
  css({
    position: 'relative',
    listStyle: 'none',
  })
);

const TableOfContents = ({ items, activeItemIndex, ...rest }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Accordion baseId="article_toc" expandedByDefault {...rest}>
      <Text fontWeight="bold">Table of contents</Text>
      <TocWrapper as="ul" mt={3}>
        {items.map((item, index) => (
          <TocItem
            key={item.id}
            item={item}
            isActive={index === activeIndex}
            onClick={() => {
              setActiveIndex(index);
            }}
            onActivated={() => setActiveIndex(index)}
          />
        ))}
      </TocWrapper>
    </Accordion>
  );
};

TableOfContents.propTypes = {
  ...tableOfContentsProps,
};

TableOfContents.defaultProps = {
  ...tableOfContentsDefaultProps,
};

export default TableOfContents;
