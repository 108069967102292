import styled from 'styled-components';
import css from '@styled-system/css';
import { variant as styledVariant } from 'styled-system';

import {
  ctaBlockDefaultProps,
  ctaBlockProps,
} from 'humanity/components/ctaBlock/ctaBlock.types';
import Box from 'humanity/primitives/box';
import Heading from 'humanity/primitives/heading';
import Text from 'humanity/primitives/text';
import Cta from 'humanity/components/cta';

// apparently cards just change from page to page, so we get to make a new one each time
const Card = styled(Box)(
  css({
    display: ['none', null, null, null, 'block'],
    borderRadius: 'sm',
  }),
  styledVariant({
    variants: {
      headerNav: {
        px: 6,
        py: 5,
      },
      article: {
        p: 3,
      },
    },
  })
);

const ctaLocations = {
  headerNav: 'header_nav_menu',
  article: 'article_sidebar',
};

const CtaBlock = ({ backgroundColor, heading, paragraph, cta, variant, ...rest }) => (
  <Card bg={backgroundColor} variant={variant} {...rest}>
    {variant === 'headerNav' && (
      <Heading
        as="h5"
        variant="h5"
        dangerouslySetInnerHTML={{ __html: heading.trim() }}
      />
    )}
    {variant === 'article' && (
      <Text
        variant="body"
        fontWeight={600}
        dangerouslySetInnerHTML={{ __html: heading.trim() }}
      />
    )}
    {paragraph && (
      <Text
        variant="bodySmall"
        dangerouslySetInnerHTML={{ __html: paragraph.trim() }}
        mt={3}
      />
    )}
    <Cta
      location={ctaLocations[variant]}
      {...cta}
      mt={3}
      width={variant === 'article' ? 1 : 'auto'}
    />
  </Card>
);

CtaBlock.propTypes = {
  ...ctaBlockProps,
};

CtaBlock.defaultProps = {
  ...ctaBlockDefaultProps,
};

export default CtaBlock;
