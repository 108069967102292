import { useTheme } from 'styled-components';

import { socialLinkProps } from 'humanity/components/socialLink/socialLink.types';
import Flex from 'humanity/primitives/flex';
import VisuallyHidden from 'humanity/components/visuallyHidden';

const SocialLink = ({
  Icon,
  title,
  iconColor,
  size = 32,
  bg = 'teal100',
  bgHover = 'tealDark',
  ...props
}) => {
  const theme = useTheme();

  return (
    <Flex
      as="a"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: 'circle',
        width: size,
        height: size,
        bg,
        cursor: 'pointer',
        transition: 'background-color 300ms ease-in-out',
        '&:hover': {
          bg: bgHover,
        },
        '&:focus': {
          outline: 0,
          boxShadow: 'buttonFocus',
        },
      }}
      title={title}
      {...props}
    >
      <Icon
        size={Math.floor(size / 1.45)}
        color={iconColor || theme.colors.white}
        focusable={false}
        aria-hidden
      />
      <VisuallyHidden>{title}</VisuallyHidden>
    </Flex>
  );
};

SocialLink.propTypes = {
  ...socialLinkProps,
};

export default SocialLink;
